import * as React from 'react';

import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import {
    getTwitterAccountByLanguage,
    getCanonical,
    getLanguageAlternates,
    PARTNER_NAME,
    SHARE_IMAGE_URL,
} from 'lib/seo/seo';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { RouteName } from 'containers/routes';
import { seoDescription } from 'containers/seo/seo-description';
import { seoTitle } from 'containers/seo/seo-title';

export const SeoContainer: React.FunctionComponent<{ lang: SupportedLanguage }> = ({ lang }) => {
    const router = useRouter();
    const title = seoTitle[router.pathname as RouteName];
    const description = seoDescription[router.pathname as RouteName];

    return (
        <DefaultSeo
            title={title}
            description={description}
            languageAlternates={getLanguageAlternates(
                'https://bumble.com',
                router.asPath,
                router.query,
                router.pathname,
            )}
            canonical={getCanonical('https://bumble.com', router.asPath)}
            facebook={{
                appId: '428250913904849',
            }}
            additionalMetaTags={[
                {
                    name: 'apple-itunes-app',
                    content: 'app-id=930441707',
                },
                {
                    property: 'p:domain_verify',
                    content: '26cd87d4b7db08a05bdfa2219d3671b6',
                },
                {
                    property: 'referrer',
                    content: 'origin-when-cross-origin',
                },
                {
                    property: 'twitter:app:name:iphone',
                    content: title,
                },
                {
                    property: 'twitter:app:id:iphone',
                    content: '930441707',
                },
            ]}
            openGraph={{
                type: 'website',
                locale: lang,
                url: `https://bumble.com/${lang}/`,
                site_name: PARTNER_NAME,
                title,
                description,
                images: [{ url: SHARE_IMAGE_URL }],
            }}
            twitter={{
                handle: '@bumble',
                cardType: 'summary_large_image',
                site: `@${getTwitterAccountByLanguage()}`,
            }}
        />
    );
};
