/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "返回",
      "在 App Store 下載",
      "在 Google  Play 下載",
      "圖文",
      "簡報 {{number}}",
      "下一張簡報",
      "上一張簡報",
      "簡報",
      "簡報 {{number}}，共 {{total}} 頁",
      "不可販售或分享我的個人資料",
      "不許售賣或分享我的個人信息",
      "Bumble 首頁",
      "選擇選單",
      "打開選單",
      "選擇語言",
      "腳註",
      "Accessibility at Bumble",
      "Apple 和 Apple 標誌是 Apple Inc. 的商標",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作機會",
      "活動",
      "Google Play 和 Google Play 標誌是 Google LLC 的商標。",
      "指導原則",
      "投資家",
      "管理 Cookie",
      "管理 Cookie",
      "現代奴役法案聲明",
      "資料收集聲明",
      "隱私權政策",
      "不同季節的約會指南",
      "條款",
      "應用",
      "Washington Consumer Health Data Privacy Policy",
      "關於",
      "大使",
      "嗡嗡",
      "商店",
      "主要",
      "直接前往主內容",
      "Bumble 標誌",
      "在這裡，友善最性感，做自己最完美，且由女性採取主動！",
      "Make The First Move™ 的堡壘",
      "美好的生活離不開健康的人際關係。無論是約會、交友、拓展人際網，Bumble 都是最有力的建立人際關係的社交工具。",
      "Bumble 的重要性",
      "我們鼓勵正直、友善、平等、自信，並尊重所有階段的關係。我們更是歡迎所有性向的人，不論是要找另一半，還是單純交友。",
      "Bumble 的價值",
      "在 Bumble，女性需要邁出第一步，或是設定 Opening Move 讓所有新配對都可以回覆。我們顛覆傳統約會方式，鼓勵兩性平權。",
      "Bumble 運作方式",
      "關於我們",
      "馬上下載",
      "App 的體驗更好",
      "使約會變得更加有意思的新功能",
      "2024 年 2 月 12 日",
      "如何增加感情的情感親密度",
      "2024 年 1 月 25 日",
      "沒有使用過約會應用程式的人該如何使用 Bumble",
      "2024 年 2 月 5 日",
      "Deception Detector™",
      "約會最新消息",
      "瀏覽<b>推薦給你</b>查看我們根據你的偏好和配對推薦給你的檔案。",
      "為你精選",
      "透過<b>瞌睡模式</b>先暫停一下，準備好約會時再回歸。",
      "休息一下",
      "使用<b>視訊</b>和<b>語音通話</b>功能，在出門見面之前先認識對方。",
      "來電了",
      "查看更多",
      "使用 <b>Opening Moves</b> 讓對方開啟對話，你也不用想破腦袋。",
      "輕鬆約會",
      "透過<b>旅行模式</b>在全球配對。",
      "帶我一起飛",
      "對方檔案看起來還不錯？透過<b>超級滑動</b>讓對方知道。",
      "吸引對方注意",
      "送出 <b>Compliments</b> 讓自己脫穎而出。",
      "讓對方臉紅心跳",
      "使用<b>隱身模式</b>隱藏檔案，不讓同事、親友或前任看見你。",
      "臥底行動",
      "照自己的方式約會",
      "幾個月後，她也在那遇見她的伴娘",
      "Hannah 在 Bumble 上遇見她的老公。",
      "在一起建立小窩之前，Carène 和 You-Liang 在 Bumble 上相遇，當時 You-Liang 受到 Carène 的旅遊照片吸引。",
      "Carène 和 You-Liang",
      "Brian 和 Kaci 都派駐在海外，但他們是在 Bumble 上相遇的",
      "Brian 和 Kaci",
      "如果他們可以，你也可以",
      "邁出下一步",
      "更新內容",
      "你值得更好的體驗，我們設計的全新方式讓你無壓交友。",
      "不需要降低標準",
      "關於 Bumble",
      "我們是唯一優先考量女性約會體驗的交友應用程式，因為女性快樂的世界，是更好的世界。",
      "Make the first move™",
      "尋找想約會的對象，並勇敢邁出第一步。建立真誠的連結、透過共同興趣認識對方，並享受快樂時光。",
      "透過 Bumble Date 尋找特別的那個人",
      "尋找職涯路上的好友，與其他同業結交，尋找新工作，創造事業。",
      "透過 Bumble Bizz 拓展人脈",
      "遇見同道中人，或是不同世界的人！不論是在家中或在路上，交新朋友，一起展開新冒險！",
      "在 Bumble For Friends 上交新朋友",
      "這個平台已不再限於約會",
      "Bumble 這個約會應用程式，由女性邁出第一步，建立有意義的關係跟社群。立即下載。",
      "Bumble 的 {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "對不起，無法找到該網頁。",
      "與我們聯絡",
      "登入",
      "糟糕，出錯啦",
      "(opens in new window)",
      "熱門問題集",
      "Cookie Policy",
      "Bumble - 博客",
      "工作機會",
      "聊天",
      "城市",
      "管理cookies",
      "版權所有 © 2006 – 至今。Bumble保留所有版權",
      "自信約會報告",
      "Apple和Apple標誌是Apple Inc.的商標。{{__EOL__}}Google Play和Google Play標誌是Google LLC.的商標",
      "下載",
      "Facebook",
      "追蹤我們",
      "幫助中心",
      "Instagram",
      "語言",
      "LinkedIn",
      "Bumble屬於Bumble Inc",
      "隱私政策",
      "安全中心",
      "網站導覽",
      "現代奴役法案聲明",
      "條款和細則",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小時"
            ],
            "singular": [
                  "小時"
            ]
      },
      "minute": {
            "plural": [
                  "分鐘"
            ],
            "singular": [
                  "分鐘"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
