/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Zurück",
      "Laden im App Store",
      "Jetzt bei Google Play",
      "Karussell",
      "Folie {{number}}",
      "Nächste Folie",
      "Vorherige Folie",
      "Folie",
      "Folie {{number}} von {{total}}",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Bumble Startseite",
      "Menü schließen",
      "Menü öffnen",
      "Sprachauswahl",
      "Fußzeile",
      "Accessibility at Bumble",
      "Apple und das Apple Logo sind Marken von Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Karriere",
      "Events",
      "Google Play und das Google Play Logo sind Marken von Google LLC.",
      "Richtlinien",
      "Investoren",
      "Cookies verwalten",
      "Cookies verwalten",
      "Erklärung zum Modern Slavery Act",
      "Hinweis zur Datenerhebung",
      "Datenschutz",
      "Saisonale Dating-Ratgeber",
      "AGB",
      "Die App",
      "Washingtoner Datenschutzrichtlinie für Gesundheitsdaten von Verbrauchern",
      "Über uns",
      "Ambassadors",
      "Blog",
      "Der Shop",
      "Hauptmenü",
      "Zum Hauptteil springen",
      "Bumble Logo",
      "Hier gilt: Kindness ist sexy, du bist perfekt, wie du bist, und Frauen kommen zuerst. Immer.",
      "Das Zuhause von Mach den ersten Schritt™",
      "Gesunde Beziehungen sind wichtig für ein glückliches Leben. Bumble ist dafür gemacht, dass du genau solche Beziehungen findest - egal, ob du Herzklopfen suchst, neue Freunde finden oder beruflich networken willst.",
      "Warum Bumble wichtig ist",
      "Wir setzen uns für Integrität, Kindness, Gleichberechtigung, Vertrauen und Respekt in jeder Phase einer Beziehung ein. Wir unterstützen nicht nur jede Orientierung, wir feiern sie - egal, ob du hier die große Liebe finden oder einfach nur Spaß haben willst.",
      "Was Bumble wertschätzt",
      "Auf Bumble bestimmen Frauen, was sie wollen, indem sie selbst den ersten Schritt machen oder einen Opening Move vorgeben, auf den alle Matches antworten können. Das verändert die altmodische Machtdynamik und fördert die Gleichberechtigung von Anfang an.",
      "Wie Bumble funktioniert",
      "Über uns",
      "Jetzt herunterladen",
      "Die App ist sogar noch besser.",
      "Features, die Online-Dating einfach viel angenehmer machen",
      "12. Februar 2024",
      "Wie du emotionale Intimität in deiner Beziehung förderst",
      "25. Jan. 2024",
      "Wie du Bumble nutzt, wenn du noch nie eine Dating-App benutzt hast",
      "5. Februar 2024",
      "Deception Detector™",
      "Druckreife Dating-News",
      "Browse in <b>Für dich</b>, um empfohlene Profile zu sehen, die zu deinen Vorlieben und früheren Matches passen.",
      "Für dich ausgewählt",
      "Pause vom Dating? Nutze den <b>Snooze-Modus</b>. Komm einfach zurück, wenn du wieder Lust hast und mach da weiter, wo du warst.",
      "Mach eine Pause",
      "Lern dein Match besser kennen, bevor du IRL auf ein Date gehst. Nutze <b>Videoanrufe</b> und <b>Sprachnachrichten</b>.",
      "Hör dir den Vibe an",
      "Mehr anzeigen",
      "Leg deinen <b>Opening Move</b> fest und gib deinem Match ein Gesprächsthema vor, um den Chat zu starten.",
      "Mehr Chats, ohne Stress",
      "Finde deinen Duty-Free-Snack und matche überall auf der Welt, mit dem <b>Reise-Modus</b>.",
      "Nimm uns mit",
      "Ein Profil, das dir richtig gut gefällt? Zeig, dass es was besonderes ist, mit einem <b>SuperSwipe</b>.",
      "Einfach auffallen",
      "Wenn dir was gefällt in einem Profil, schick ein <b>Kompliment</b> und connecte, um herauszustechen.",
      "Mach jemanden happy",
      "Nutze <b>Inkognito-Modus</b>, wenn nur Leute dein Profil sehen sollen, die für dich infrage kommen, nicht dein Chef oder deine Verwandten.",
      "Geh undercover",
      "Date nach deinen Regeln",
      "Ein paar Monate später traf sie dort auch eine ihrer Brautjungfern",
      "Hannah lernte ihren jetzigen Ehemann auf Bumble kennen.",
      "Bevor sie zusammen ihr Tiny-House bauten, lernten sich Carène und You-Liang auf Bumble kennen, wo You-Liang von Carènes Reisefotos fasziniert war.",
      "Carène und You-Liang",
      "Brian und Kaci waren zusammen im Ausland stationiert - aber sie haben sich auf Bumble kennengelernt",
      "Brian und Kaci",
      "Wenn es für sie funktioniert hat, könnte es auch für dich funktionieren.",
      "Mach den nächsten Move",
      "Neues entdecken",
      "Du hast was Besseres verdient, deshalb haben wir für dich tolle neue Möglichkeiten geschaffen, mit denen du mehr Dates findest und zwar ohne den ganzen Stress.",
      "Leg die Messlatte wieder höher, wo sie hingehört",
      "Über Bumble",
      "Bumble ist die einzige App, die Dating besser macht, denn hier steht das Erlebnis von Frauen im Vordergrund. Wenn etwas besser ist für Frauen, dann ist es besser für alle.",
      "Make the first move™",
      "Finde jemanden, den du so richtig gerne daten willst, und dann mach den ersten Schritt. Geh in die Connection, bonde über Dinge, die ihr beide liebt, und vor allem: habt Spaß dabei.",
      "Lern jemanden kennen mit Bumble Date",
      "Finde Freunde mit Work-Benefits. Connecte mit anderen Professionals, um deinen nächsten Job, einen Mentor oder sogar eine neue Karriere zu finden.",
      "Mach Boss-Moves mit Bumble Bizz",
      "Lerne Leute kennen, die so sind wie du. Oder anders. Finde neue Freunde und entdecke Dinge, die ihr zusammen unternehmen könnt - ob zu Hause oder unterwegs.",
      "Finde neue Freunde auf Bumble For Friends",
      "So viel mehr als Dating",
      "Bumble ist die Dating-App, bei der Frauen den ersten Schritt machen. Die App, die revolutioniert hat, wie wir heute daten, gesunde Beziehungen aufbauen und networken. Jetzt herunterladen.",
      "Bumble auf {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Wir konnten die gesuchte Seite leider nicht finden.",
      "Kontakt",
      "Einloggen",
      "Sorry, da ist was schiefgegangen!",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Bumble – Blog",
      "Karriere",
      "Chat",
      "Städte",
      "Cookies verwalten",
      "Copyright © 2006 bis heute. Bumble. Alle Rechte vorbehalten.",
      "Bericht: Zuversicht beim Dating",
      "Apple und das Apple Logo sind Marken von Apple Inc.{{__EOL__}} Google Play und das Google Play-Logo sind Marken von Google LLC.",
      "Jetzt downloaden",
      "Facebook",
      "Wir sind auch hier:",
      "Hilfebereich",
      "Instagram",
      "Sprache",
      "LinkedIn",
      "Bumble ist Teil von Bumble Inc.",
      "Datenschutzrichtlinien",
      "Safety Centre",
      "Sitemap",
      "Erklärung zum Modern Slavery Act",
      "AGB",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "Tage",
                  "Tage",
                  "Tagen",
                  "Tage"
            ],
            "singular": [
                  "Tag",
                  "Tags",
                  "Tag",
                  "Tag"
            ]
      },
      "hour": {
            "plural": [
                  "Stunden",
                  "Stunden",
                  "Stunden",
                  "Stunden"
            ],
            "singular": [
                  "Stunde",
                  "Stunde",
                  "Stunde",
                  "Stunde"
            ]
      },
      "minute": {
            "plural": [
                  "Minuten",
                  "Minuten",
                  "Minuten",
                  "Minuten"
            ],
            "singular": [
                  "Minute",
                  "Minute",
                  "Minute",
                  "Minute"
            ]
      },
      "month": {
            "plural": [
                  "Monate",
                  "Monate",
                  "Monaten",
                  "Monate"
            ],
            "singular": [
                  "Monat",
                  "Monats",
                  "Monat",
                  "Monat"
            ]
      },
      "second": {
            "plural": [
                  "Sekunden",
                  "Sekunden",
                  "Sekunden",
                  "Sekunden"
            ],
            "singular": [
                  "Sekunde",
                  "Sekunde",
                  "Sekunde",
                  "Sekunde"
            ]
      },
      "year": {
            "plural": [
                  "Jahre",
                  "Jahre",
                  "Jahren",
                  "Jahre"
            ],
            "singular": [
                  "Jahr",
                  "Jahres",
                  "Jahr",
                  "Jahr"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
