import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { supportedLanguages } from 'lib/supported-languages';

const mapInstagramAccountByLanguage: { [key in SupportedLanguage]?: string } = {
    en: 'bumble',
    de: 'bumble_de',
    'en-in': 'bumble_india',
    hi: 'bumble_india',
};

const mapHrefLang: { [key in SupportedLanguage]?: string } = {
    en: 'en-gb',
};

export const PARTNER_NAME = 'Bumble';

export const SHARE_IMAGE_URL = 'https://bumble.com/bumble-brand-assets/bumble-share.jpg';

export function getTwitterAccountByLanguage(): string {
    return 'bumble';
}

export function getInstagramAccountByLanguage(lang: SupportedLanguage): string {
    return (
        mapInstagramAccountByLanguage[lang] ||
        (mapInstagramAccountByLanguage[__DEFAULT_LANG__] as string)
    );
}

function setLanguageAlternates(baseUrl: string, path?: string) {
    return supportedLanguageIds.map((lang) => {
        const hrefLang = mapHrefLang[lang] || lang;

        return setLanguageAlternatesItem(baseUrl, hrefLang, lang, path);
    });
}

function setLanguageAlternatesItem(baseUrl: string, hrefLang: string, lang: string, path?: string) {
    let languageAlternateLink = `${baseUrl}/${lang}/${path || ''}`;

    if (!languageAlternateLink.endsWith('/')) {
        languageAlternateLink += '/';
    }

    return {
        hrefLang,
        href: languageAlternateLink,
    };
}

export function getLanguageAlternates(
    baseUrl: string,
    route: string,
    query: any,
    pathname?: string,
) {
    let languageAlternateList = setLanguageAlternates(baseUrl);
    const knownLangRoute = startsWithSupportedLanguage(query.lang);

    if (pathname && pathname.startsWith('/[lang]/')) {
        const knownPath = startsWithSupportedPath(pathname.replace('/[lang]', ''));

        if (knownPath) {
            languageAlternateList = setLanguageAlternates(
                baseUrl,
                getPathnameWithoutLangPrefix(pathname),
            );
        }
    }

    if (pathname) {
        const knownPath = startsWithSupportedPath(pathname);

        if (knownPath) {
            languageAlternateList = setLanguageAlternates(
                baseUrl,
                getPathnameWithoutLangPrefix(pathname),
            );
        }
    }

    if (knownLangRoute) {
        languageAlternateList = setLanguageAlternates(baseUrl, getPathnameWithoutLangPrefix(route));
    }

    return languageAlternateList;
}

export function getCanonical(baseUrl: string, route: string) {
    let canonicalUrl = baseUrl;
    const pathName = route.includes('?') ? route.replace(/\?.+/g, '') : route;

    const knownPath = startsWithSupportedPath(route);
    const knownLangRoute = startsWithSupportedLanguage(route);

    if (knownPath) {
        canonicalUrl = `${baseUrl}/${getPathnameWithoutLangPrefix(pathName)}`;
    }

    if (knownLangRoute) {
        canonicalUrl = `${baseUrl}/${getPathnameWithoutLangPrefix(pathName)}`;
    } else {
        if (knownPath) {
            canonicalUrl = `${baseUrl}/en/${getPathnameWithoutLangPrefix(pathName)}`;
        } else {
            canonicalUrl = `${baseUrl}/en/`;
        }
    }

    if (!canonicalUrl.endsWith('/')) {
        canonicalUrl += '/';
    }

    if (canonicalUrl === `${baseUrl}/`) {
        canonicalUrl += 'en/';
    }

    return canonicalUrl;
}

function getPathnameWithoutLangPrefix(pathname: string) {
    const matches = /^(?:\/\[lang\])?(?:\/)?(.+)?$/.exec(pathname);
    return matches?.[1] || '';
}

/**
 * ask Creative team (James) for stats / new links
 */
export const downloadAction = 'https://bumble.onelink.me/3396940749/yuhjm82c';
export const signInAction =
    'https://bumble.com/get-started?utm_source=ab-test&utm_medium=web&utm_campaign=bumble_web_sign_in&utm_id=bumble-web-sign-in';

export function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguages.some((language) => {
        return url.startsWith(`/${language}/`);
    });
}

export function startsWithSupportedPath(url?: string) {
    const supportedPaths = ['about', 'date'];
    if (!url) {
        return false;
    }

    return supportedPaths.some((supportedPath) => {
        return url.startsWith(`/${supportedPath}`);
    });
}

export function removeTrailingSlash(url: string) {
    return url.replace(/\/$/, '');
}
