/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Gå tilbake",
      "Last ned i App Store",
      "Få det i Google Play",
      "Karusell",
      "Side {{number}}",
      "Neste side",
      "Forrige side",
      "Side",
      "Side {{number}} av {{total}}",
      "Ikke selg eller del min personlige informasjon",
      "Ikke selg eller del min personlige informasjon",
      "Bumble startside",
      "Lukk meny",
      "Åpne meny",
      "Språkvalg",
      "Footer",
      "Accessibility at Bumble",
      "Apple og Apple-logoen er varemerker fra Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobbmuligheter",
      "Eventer",
      "Google Play og Google Play-logoen er varemerker fra Google LLC.",
      "Retningslinjer",
      "Investorer",
      "Administrer cookies",
      "Administrer cookies",
      "Modern Slavery Act Statement",
      "Lagring av data",
      "Personvern",
      "Sesongbestemte dating-guider",
      "Vilkår & Betingelser",
      "Appen",
      "Personvernsreglement for Consumer Health Data i Washington",
      "Om",
      "Ambassadører",
      "The Buzz",
      "The Shop",
      "Hoved",
      "Hopp til hovedside",
      "Bumble logo",
      "Hos oss mener vi at vennlighet er sexy, og her kan du være deg selv. Og kvinnene sitter i førersetet.",
      "Ta det første steget",
      "Vi tror at sunne forhold er noe av det viktigste i livet. Bumble er designet for å gi alle en plattform med fokus på gjensidig respekt og likestilling – i dating, nye vennskap, potensielle forhold eller i nye karriererelasjoner.",
      "Hvorfor Bumble er viktig",
      "Vi oppmuntrer til integritet, likestilling og respekt fra en match oppstår. Alle er velkomne her – uansett legning, og uansett hva du ser etter.",
      "Hva Bumble står for",
      "På Bumble er det kvinnene som setter an tonen ved å ta det første steget, eller sende en Opening Move til matcher. Vi har endret dynamikken og oppmuntrer til likestilling helt fra starten.",
      "Hvordan Bumble fungerer",
      "Om oss",
      "Last ned nå",
      "Det er bedre på appen.",
      "Tjenester designet for å gjøre nettdating – faktisk – fornøyelig",
      "12. februar 2024",
      "Hvordan forbedre og øke emosjonell intimitet i forhold",
      "25. januar 2024",
      "Hvordan bruke Bumble når du aldri har brukt en dating-app før",
      "5. februar 2024",
      "Deception Detector™",
      "Alle dating-nyhetene du trenger",
      "Sjekk ut <b>Til Deg</b> for å få utvalgte profiler basert på dine preferanser og tidligere matcher.",
      "Utvalgt til deg",
      "Ta en pause med <b>Slumre-modus</b>. Du vet hvor vi er når du er klar for dating igjen.",
      "Ta en pause",
      "Bruk <b>videosamtale</b> og <b>lydsamtale</b> for å bli kjent før dere treffes på ekte.",
      "Sett på lyden",
      "Se mer",
      "Prøv <b>Opening Moves</b> for å få chatten i gang på en kul måte.",
      "Mindre mas, mer dating",
      "Finn matcher hvor som helst i verden med <b>Reisemodus</b>.",
      "Bli med på tur",
      "Imponert av profilen deres? La dem få vite det med en <b>SuperSwipe</b>.",
      "Fang deres oppmerksomhet",
      "Send <b>Compliments</b> for å skille deg ut i mengden.",
      "Gi dem et smil",
      "Bruk <b>Usynlighet</b> hvis du vil skjule profilen din for noen – for eksempel eksen eller sjefen.",
      "Gå undercover",
      "Dating på din måte",
      "Noen måneder senere, møtte hun en av brudepikene der, også",
      "Hannah møtte sin fremtidige mann på Bumble.",
      "Før de bygde mikrohuset sitt sammen, møttes Carène og You-Liang på Bumble – hvor You-Liang ble fascinert av reisebildene til Carène.",
      "Carène og You-Liang",
      "Brian og Kaci var stasjonert i utlandet sammen – men de møttes på Bumble",
      "Brian og Kaci",
      "Det funket for dem, det kan funke for deg også",
      "Ta det neste steget",
      "Se hva som er nytt",
      "Du fortjener det beste. Derfor har vi designet fantastiske måter du kan date mer, og stresse mindre på.",
      "Sett listen høyt igjen",
      "Om Bumble",
      "Vi er den eneste appen som setter kvinnens opplevelse først. For vi tror på at når ting er bedre for kvinner, er det bedre for alle.",
      "Make the first move™",
      "Finn gnisten med noen! Ta det første steget, finn en genuin forbindelse, bli kjent over felles interesser, men viktigst av alt – ha det gøy.",
      "Treff noen med Bumble Date",
      "Finn venner eller mentorer. Bli kjent med andre i bransjen for å finne din neste jobb, noen å sparre med eller en helt ny karriere.",
      "Bygg nettverk med Bumble Bizz",
      "Treff noen som deg. Eller ikke som deg. Finn nye vennskap og ting å finne på sammen – uansett om du er hjemme eller på tur.",
      "Finn nye venner på Bumble For Friends",
      "Ikke kun for dating",
      "Bumble er dating-appen hvor damene tar det første steget. Det er appen som har endret måten folk dater på, danner meningsfulle vennskap og bygger nettverk. Last ned nå.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Vi beklager, vi kunne ikke finne siden du leter etter.",
      "Kontakt oss",
      "Logg inn",
      "Oops, det oppsto en feil",
      "(opens in new window)",
      "Ofte stilte spørsmål",
      "Cookie Policy",
      "Bumble - The Blog",
      "Jobbmuligheter",
      "Chat",
      "Byer",
      "Administrer cookies",
      "Copyright © 2006 – nå. Bumble. Alle rettigheter forbeholdes.",
      "Datingrapport",
      "Apple og Apple-logoen er varemerker til Apple Inc.{{__EOL__}}Google Play og Google Play-logoen er varemerker til Google LLC.",
      "Last ned",
      "Facebook",
      "Følg oss",
      "Hjelpesenter",
      "Instagram",
      "Språk",
      "LinkedIn",
      "Bumble er en del av Bumble Inc.",
      "Personvernerklæring",
      "Safety Centre",
      "Sidekart",
      "Modern Slavery Act statement",
      "Vilkår og betingelser",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dager"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "timer"
            ],
            "singular": [
                  "time"
            ]
      },
      "minute": {
            "plural": [
                  "minutter"
            ],
            "singular": [
                  "minutt"
            ]
      },
      "month": {
            "plural": [
                  "måneder"
            ],
            "singular": [
                  "måned"
            ]
      },
      "second": {
            "plural": [
                  "sekunder"
            ],
            "singular": [
                  "sekund"
            ]
      },
      "year": {
            "plural": [
                  "år"
            ],
            "singular": [
                  "år"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
