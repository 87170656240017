/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Вернуться",
      "Доступно в App Store",
      "Доступно в Google Play",
      "Карусель",
      "Слайд {{number}}",
      "Следующий",
      "Предыдущий",
      "Слайд",
      "Слайд {{number}} из {{total}}",
      "Не продавать и не передавать мою личную информацию",
      "Не продавать и не передавать мою личную информацию",
      "Домашняя страница Bumble",
      "Закрыть меню",
      "Открыть меню",
      "Выбор языка",
      "Сноска",
      "Accessibility at Bumble",
      "Apple и логотип Apple являются товарными знаками компании Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Карьера",
      "События",
      "Google Play и логотип Google Play являются товарными знаками компании Google LLC.",
      "Правила",
      "Инвесторы",
      "Настройки cookie",
      "Настройки cookie",
      "Закон о современном рабстве",
      "Положение о сборе персональных данных",
      "Конфиденциальность",
      "Сезонные дейтинг-советы",
      "Условия обслуживания",
      "Приложение",
      "Политика конфиденциальности Вашингтона в отношении клиентских данных об исправности систем",
      "О нас",
      "Амбассадоры",
      "Блог",
      "Магазин",
      "Основное",
      "Вернуться к основному контенту",
      "Логотип Bumble",
      "Мы ценим доброту, призываем вас быть собой и всегда заботимся, в первую очередь, об интересах женщин.",
      "Место, где легко сделать первый шаг",
      "Мы не можем жить без отношений. Bumble — это место, где можно найти любовь, друзей или новые карьерные возможности.",
      "Философия Bumble",
      "Мы выступаем за честность, доброту, равенство, здоровую самооценку и уважение на всех этапах отношений. Мы не просто принимаем, а горячо приветствуем любую ориентацию и любые намерения – как поиск серьёзных отношений, так и желание приятно провести время.",
      "Ценности Bumble",
      "На Bumble девушки правят бал, делая первый шаг или выбирая Opening Move, на который могут отреагировать их симпатии. Это наш ответ устаревшей расстановке сил и призыв к равенству с самого начала.",
      "Как функционирует Bumble",
      "О нас",
      "Скачать",
      "Скачайте приложение – так удобнее.",
      "С этими функциями знакомиться онлайн ещё приятнее",
      "12 февраля, 2024",
      "Как укрепить эмоциональную близость в отношениях",
      "25 января, 2024",
      "Инструкция к Bumble для тех, кто никогда раньше не пользовался дейтинг-приложением",
      "5 февраля, 2024",
      "Deception Detector™",
      "Лучшие новости из мира дейтинга",
      "<b>Для вас</b> – это ваша личная подборка профилей на основе ваших предпочтений и предыдущих симпатий.",
      "Подборка для вас",
      "Хотите немного отдохнуть от дейтинга? Включите <b>режим неведимки</b>. Обещаем, мы вас дождёмся!",
      "Перерыв? Не вопрос!",
      "<b>Видеозвонки</b> и <b>голосовые чаты</b> помогут вам максимально приблизиться к атмосфере реальной встречи, не выходя из дома.",
      "Скажите громко",
      "Показать больше",
      "Выберите <b>Opening Moves</b> и ждите, пока ваша симпатия сама инициирует общение.",
      "Меньше усилий, больше удовольствия",
      "Захватите что-нибудь вкусненькое в дьюти-фри, включите <b>режим Путешествий</b> и знакомьтесь в любой точке мира.",
      "Возьмите нас с собой в путешествие",
      "Понравился кто-то? Сообщите о своих чувствах с помощью <b>SuperSwipe</b>.",
      "Привлеките внимание",
      "Используйте <b>Compliments</b>, чтобы привлечь внимание вашей симпатии.",
      "Подарите улыбку",
      "Используйте <b>режим Инкогнито</b>, чтобы скрыть свой профиль от начальника, родственников или бывших.",
      "Скройте ваш профиль",
      "Дейтинг по вашим правилам",
      "Спустя несколько месяцев она нашла здесь и одну из подружек невесты",
      "Ханна познакомилась с будущим мужем на Bumble.",
      "Карин и Ю Лиан познакомились на Bumble – Ю Линг привлекли фотографии Карин из путешествий. И теперь они вместе построили собственный уютный домик!",
      "Карин и Ю Лиан",
      "Брайан и Кэси вместе работали за границей – а познакомились на Bumble",
      "Брайан и Кэси",
      "Это наши истории успеха, и вы легко можете стать одной из них",
      "Сделайте следующий шаг",
      "Узнать, что нового",
      "Вы достойны самого лучшего! Наши способы дейтинга подарят вам больше удовольствия и меньше стресса.",
      "Поднимите вашу планку на достойную высоту",
      "Подробнее о Bumble",
      "Мы единственное приложение, где интересы женщин всегда на первом месте. Ведь если позаботиться о них, станет лучше всем.",
      "Make the first move™",
      "Когда вам кто-то понравится, смело делайте первый шаг. Ныряйте в глубину, узнайте, что у вас общего и, самое главное, просто наслаждайтесь процессом.",
      "Влюбляйтесь на Bumble Date",
      "Находите полезные знакомства, которые приведут вас к новой работе, вдохновляющим наставникам или – почему бы и нет – к смене карьеры.",
      "Стройте карьеру на Bumble Bizz",
      "Будьте парой, как два сапога. Или притягивайтесь, как противоположности. Заводите новых друзей и находите общие занятия по душе – и дома, и в путешествиях.",
      "Находите новых друзей на Bumble For Friends",
      "Мы не только про дейтинг",
      "Bumble – это дейтинг-приложение, где девушки делают первый шаг. Оно навсегда изменило мир дейтинга, поиска друзей и нетворкинга. Загрузите Bumble и знакомьтесь с удовольствием.",
      "Bumble в {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Страница не найдена :(",
      "Обратная связь",
      "Войти",
      "Кажется, что-то пошло не так",
      "(откроется в новом окне)",
      "Вопросы/ответы",
      "Политика использования файлов cookie",
      "Блог Bumble",
      "Карьера",
      "Чат",
      "Города",
      "Настройки cookie",
      "© 2006 – н.в. Bumble. Все права защищены.",
      "Уверенность на свиданиях – отчет",
      "Apple и логотип Apple являются товарными знаками компании Apple Inc.{{__EOL__}}Google Play и логотип Google Play являются товарными знаками компании Google LLC.",
      "Скачать",
      "Facebook",
      "Подпишись на нас:",
      "Помощь",
      "Instagram",
      "Язык",
      "LinkedIn",
      "Bumble – часть Bumble Inc.",
      "Политика конфиденциальности",
      "Safety Centre",
      "Карта сайта",
      "Закон о современном рабстве",
      "Условия обслуживания",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "many": [
                  "дней",
                  "дней",
                  "дням",
                  "дней",
                  "днями",
                  "днях"
            ],
            "plural": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "singular": [
                  "день",
                  "дня",
                  "дню",
                  "день",
                  "днём",
                  "дне"
            ],
            "two": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ]
      },
      "hour": {
            "few": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "many": [
                  "часов",
                  "часов",
                  "часам",
                  "часов",
                  "часами",
                  "часах"
            ],
            "plural": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "singular": [
                  "час",
                  "часа",
                  "часу",
                  "час",
                  "часом",
                  "часе"
            ],
            "two": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ]
      },
      "minute": {
            "few": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "many": [
                  "минут",
                  "минут",
                  "минутам",
                  "минут",
                  "минутами",
                  "минутах"
            ],
            "plural": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "singular": [
                  "минута",
                  "минуты",
                  "минуте",
                  "минуту",
                  "минутой",
                  "минуте"
            ],
            "two": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ]
      },
      "month": {
            "few": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "many": [
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцами",
                  "месяцах"
            ],
            "plural": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "singular": [
                  "месяц",
                  "месяца",
                  "месяц",
                  "месяц",
                  "месяцем",
                  "месяце"
            ],
            "two": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ]
      },
      "second": {
            "few": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "many": [
                  "секунд",
                  "секунд",
                  "секундам",
                  "секунд",
                  "секундами",
                  "секундах"
            ],
            "plural": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "singular": [
                  "секунда",
                  "секунды",
                  "секунде",
                  "секунду",
                  "секундой",
                  "секунде"
            ],
            "two": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ]
      },
      "year": {
            "few": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "many": [
                  "лет",
                  "лет",
                  "годам",
                  "лет",
                  "годами",
                  "годах"
            ],
            "plural": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "singular": [
                  "год",
                  "года",
                  "году",
                  "год",
                  "годом",
                  "годе"
            ],
            "two": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
