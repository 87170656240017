/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Wróć",
      "Pobierz w App Store",
      "Pobierz w Google Play",
      "Karuzela",
      "Slajd {{number}}",
      "Następny slajd",
      "Poprzedni slajd",
      "Slajd",
      "Slajd {{number}} z {{total}}",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Strona główna Bumble",
      "Zamknij menu",
      "Otwórz menu",
      "Wybór języków",
      "Stopka",
      "Accessibility at Bumble",
      "Apple i logo Apple to znaki towarowe Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Praca",
      "Wydarzenia",
      "Google Play i logo Google Play to znaki towarowe Google LLC.",
      "Zasady użytkowania",
      "Inwestorzy",
      "Zarządzaj plikami cookie",
      "Zarządzaj plikami cookie",
      "Oświadczenie w spr. Ustawy o współczesnym niewolnictwie (GB)",
      "Powiadominie w przypadku gromadzenia danych",
      "Polityka Prywatności",
      "Sezonowe porady randkowe",
      "Regulamin",
      "Aplikacja",
      "Polityka prywatności danych Washington Consumer Health",
      "O nas",
      "Ambasadorzy marki",
      "Blog",
      "Sklep",
      "Główna",
      "Przejdź do głównej treści",
      "Logo Bumble",
      "Tutaj uprzejmość jest sexy, bycie sobą jest idealne, a kobiety mają pierwszeństwo.",
      "To tu króluje przesłanie Zrób pierwszy krok",
      "Zdrowe relacje to podstawa pozytywnego i produktywnego życia. Bumble zostało stworzone po to, aby wspierać Cię podczas zawierania znajomości. Niezależnie od tego, czy szukasz randek, znajomych, czy chcesz poszerzać kręgi zawodowe.",
      "Dlaczego Bumble ma znaczenie",
      "Na pierwszym miejscu stawiamy życzliwość, równość, pewność siebie i szacunek na wszystkich etapach relacji. Jesteśmy nie tylko otwarci na wszystkie orientacje, ale wręcz je celebrujemy – niezależnie od tego, czy jesteś tu po to, aby znaleźć miłość, czy po prostu dobrze się bawić.",
      "Co ceni Bumble",
      "W Bumble to kobiety nadają ton, albo Robią pierwszy krok, albo ustawiając Opening Move, na które mogą odpowiedzieć dopasowania. Zmienia to staromodną dynamikę i już od samego początku wspiera zasady równości.",
      "Jak działa Bumble",
      "O nas",
      "Pobierz teraz",
      "Lepiej w aplikacji.",
      "Funkcje stworzone po to, by sprawić, aby Twoje życie randkowe było po prostu przyjemne",
      "12 lutego 2024",
      "Jak zwiększyć intymność emocjonalną w swom związku",
      "25 stycznia 2024",
      "Jak używać Bumble, jeśli się nie korzystało wcześniej z żadnej apki randkowej",
      "5 lutego 2024",
      "Deception Detector™️",
      "Wszystkie randkowe newsy, które nadają się do druku",
      "Przejrzyj <b>Dla Ciebie</b>, aby zobaczyć profile wybrane specjalnie dla Ciebie na podstawie Twoich preferencji i poprzednich dopasowań.",
      "Wybrane dla Ciebie",
      "Zrób sobie przerwę od randek z <b>Trybem uśpienia</b>. Kiedy znowu poczujesz gotowość do randek, wiesz, gdzie nas znaleźć.",
      "Zrób sobie przerwę",
      "Skorzystaj z <b>rozmów video</b> i <b>czatów głosowych</b>, aby być możliwie jak najbliżej doświadczenia na żywo bez wychodzenia z domu.",
      "Powiedz to głośno",
      "Zobacz więcej",
      "Wybierz <b>Opening Moves</b>, aby to Twoje dopasowanie zaczęło rozmowę zamiast Ciebie.",
      "Rób mniej, randkuj więcej",
      "Znajdź swoją przekąskę w sklepie bezcłowym i dopasowania w dowolnym miejscu na świecie z <b>Trybem podróży</b>.",
      "Weź nas na wynos",
      "Odpowiada Ci ten profil? Użyj <b>SuperSwipe</b> i daj tej osobie znać.",
      "Zwróć na siebie uwagę",
      "Nie szczędź kilku <b>Compliments</b>, aby wyróżnić się z tłumu.",
      "Niech się zarumieni",
      "Użyj <b>Tryu Incognito</b>, aby ukryć swój profil przed szefową, krewnymi lub byłymi.",
      "Działaj w ukryciu",
      "Randkuj tak, jak chcesz",
      "Parę miesięcy później poznała tam też jedną ze swoich druhen",
      "Hanna poznała swojego obecnego męża w Bumble.",
      "Zanim zbudowali swój wspólny mały dom Karolina i Wojtek poznali się w Bumble, do którego przyciągnęły Wojtka zamieszczane przez Karo zdjęcia z podróży.",
      "Karolina i Wojtek",
      "Krystian i Natalia stacjonowali razem za granicą—ale poznali się w Bumble",
      "Krystian i Natalia",
      "Jeśli im się udało, uda się i Tobie",
      "Wykonaj następny ruch",
      "Zobacz, co u nas nowego",
      "Zasługujesz na coś lepszego, dlatego stworzyliśmy dla Ciebie świetne sposoby na więcej randek i mniej stresu.",
      "Postaw poprzeczkę tam, gdzie powinna być",
      "O Bumble",
      "Jesteśmy jedyną apką, która na pierwszym miejscu stawia kobiety. Bo kiedy świat sprzyja kobietom, to sprzyja wszystkim.",
      "Make the first move™",
      "Znajdź kogoś, z kim naprawdę chcesz się umówić, a potem Zrób pierwszy krok. Zawieraj szczere znajomości, łącz się na podstawie wspólnych pasji, a przede wszystkim dobrze się baw.",
      "Poznaj kogoś z Bumble Date",
      "Zawieraj znajomości korzystne zawodowo. Połącz się z innymi specjalistami, aby znaleźć swoją następną pracę, mentora lub mentorkę, a może nawet rozpocząć zupełnie nową karierę.",
      "Rozwijaj się zawodowo z Bumble Bizz",
      "Poznaj kogoś podobnego do Ciebie. Albo zupełnie innego. Znajdź nowych znajomych i nowe wspólne przedsięwzięcia — niezależnie od tego, czy jesteś w domu, czy w podróży.",
      "Znajdź nowych znajomych w Bumble For Friends",
      "Nie jesteśmy tylko od randek",
      "Bumble to aplikacja randkowa, w której to kobiety robią pierwszy krok. To apka, która zmieniła sposób randkowania, tworzy przestrzeń na wartościowe relacje i sieć kontaktów. Pobierz teraz.",
      "Bumble na {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Przykro nam, ale nie nie możemy odnaleźć strony, której szukasz.",
      "Kontakt",
      "Zaloguj się",
      "Ojej, coś poszło nie tak",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Bumble - Blog",
      "Praca",
      "Czat",
      "Miasta",
      "Zarządzaj cookies",
      "Prawa autorskie © 2006 – po dziś. Bumble. Wszelkie prawa zastrzeżone.",
      "Raport z Randkowania z pewnością siebie",
      "Apple i logo Apple są znakami towarowymi firmy Apple Inc.{{__EOL__}}Google Play i logo Google Play są znakiem towarowym firmy Google LLC.",
      "Pobierz",
      "Facebook",
      "Obserwuj nas",
      "Centrum pomocy",
      "Instagram",
      "Język",
      "LinkedIn",
      "Bumble to część Bumble Inc.",
      "Polityka prywatności",
      "Safety Centre",
      "Mapa witryny",
      "Oświadczenie w spr. Ustawy o Współczesnym Niewolnictwie",
      "Regulamin",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "many": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "plural": [
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni"
            ],
            "singular": [
                  "dzień",
                  "dnia",
                  "dniowi",
                  "dzień",
                  "dniu",
                  "dniem",
                  "dzień"
            ]
      },
      "hour": {
            "few": [
                  "godziny",
                  "godzin",
                  "godzinom",
                  "godziny",
                  "godzinach",
                  "godzinami",
                  "godziny"
            ],
            "many": [
                  "godzin",
                  "godzin",
                  "godzinom",
                  "godzin",
                  "godzinach",
                  "godzinami",
                  "godzin"
            ],
            "plural": [
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin"
            ],
            "singular": [
                  "godzina",
                  "godziny",
                  "godzinie",
                  "godzinę",
                  "godzinie",
                  "godziną",
                  "godzina"
            ]
      },
      "minute": {
            "few": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "many": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "plural": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "singular": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ]
      },
      "month": {
            "few": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "many": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "plural": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "singular": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ]
      },
      "second": {
            "few": [
                  "sekundy",
                  "sekund",
                  "sekundom",
                  "sekundy",
                  "sekundach",
                  "sekundami",
                  "sekundy"
            ],
            "many": [
                  "sekund",
                  "sekund",
                  "sekundom",
                  "sekund",
                  "sekundach",
                  "sekundami",
                  "sekund"
            ],
            "plural": [
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund"
            ],
            "singular": [
                  "sekunda",
                  "sekundy",
                  "sekundzie",
                  "sekundę",
                  "sekundzie",
                  "sekundą",
                  "sekundo"
            ]
      },
      "year": {
            "few": [
                  "lata",
                  "lat",
                  "latom",
                  "lata",
                  "latach",
                  "latami",
                  "lata"
            ],
            "many": [
                  "lat",
                  "lat",
                  "latom",
                  "lat",
                  "latach",
                  "latami",
                  "lat"
            ],
            "plural": [
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat"
            ],
            "singular": [
                  "rok",
                  "roku",
                  "rokowi",
                  "rok",
                  "roku",
                  "rokiem",
                  "rok"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
